import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import classNames from 'classnames';
import CareerNotFound from '../../../components/careers/detail/CareerNotFound';
import CareerBody from '../../../components/careers/detail/CareerBody';
import emailIconWhite from '../../../assets/contact/email-icon-white.png';
import emailIconBlack from '../../../assets/contact/email-icon-black.png';
import DocumentHead from '../../../components/DocumentHead';
import { careerDetailContent, careersContent } from '../../../content/index';
// @ts-ignore
import androidDeveloper from '../../../content/careers/detail/androiddeveloper.html';
// @ts-ignore
import softwareDeveloper from '../../../content/careers/detail/softwaredeveloper.html';
// @ts-ignore
import iosDeveloper from '../../../content/careers/detail/iosdeveloper.html';
// @ts-ignore
import reactWebDeveloper from '../../../content/careers/detail/reactwebdeveloper.html';
// @ts-ignore
import reactNativeDeveloper from '../../../content/careers/detail/reactnativedeveloper.html';
// @ts-ignore
import projectManager from '../../../content/careers/detail/projectmanager.html';
// @ts-ignore
import softwareQaSpecialist from '../../../content/careers/detail/softwareqaspecialist.html';
// @ts-ignore
import uxExperienceDesigner from '../../../content/careers/detail/uxexperiencedesigner.html';
import { Career } from '../../../types/content/careers';

let htmlContent = '';
let yamlObj: Career | null = null;

interface SiteData {
  site: {
    siteMetadata: {
      siteURL: string;
    };
  };
}

const DetailPage: React.FC<PageProps<SiteData>> = ({ location }) => {
  const postingParam: string =
    new URLSearchParams(location?.search).get('posting') || '';

  const buttonClass = classNames(
    'md:px-4 px-19px py-15px',
    'border border-black rounded',
    'bg-secondary pointer-fine:hover:bg-white',
    'border-opacity-0 pointer-fine:hover:border-opacity-100',
    'text-white pointer-fine:hover:text-black',
    'transform duration-200 ease-in group',
  );

  if (postingParam) {
    getHtmlContent(postingParam);
  }

  return (
    <div className="pt-11 px-3 grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 md:pt-8 md:px-68px md:mt-8 mb-9">
      <DocumentHead content={careerDetailContent.metadata} />

      <div className="col-span-4 md:col-span-6 lg:col-span-7 text-sm md:text-base">
        {htmlContent && yamlObj?.title ? (
          <div>
            <section>
              <div className="flex flex-col items-start">
                <h1
                  className="font-extrabold text-4xl text-black"
                  data-testid="page-detail-title"
                >
                  {yamlObj.title}
                </h1>
                <div
                  className="border box-border rounded-6xl border-secondary mt-4.2"
                  data-testid="tag-container"
                >
                  <p className="font-semibold leading-5 text-center capitalize text-gray-800 py-1 px-4">
                    {yamlObj.tag}
                  </p>
                </div>
              </div>
            </section>
            <CareerBody htmlContent={htmlContent} />
            <section className="flex flex-col justify-center">
              <a
                href={`mailto:${careerDetailContent.cta.email}?subject=Ensemble ${yamlObj.title} Position`}
                className="block self-center mt-8 md:mt-7 md:self-start"
              >
                <button className={buttonClass}>
                  <div className="flex justify-center items-center space-x-3">
                    <div className="flex-none relative">
                      <img
                        className="absolute object-contain opacity-0 transform duration-200 ease-in pointer-fine:group-hover:opacity-100"
                        src={emailIconBlack}
                        alt="Envelope"
                      />
                      <img src={emailIconWhite} alt="Envelope" />
                    </div>
                    <div className="text-sm font-semibold">
                      {careerDetailContent.cta.email}
                    </div>
                  </div>
                </button>
              </a>
            </section>
          </div>
        ) : (
          <CareerNotFound />
        )}
      </div>
    </div>
  );
};

function getHtmlContent(id: string) {
  switch (id) {
    case 'softwaredeveloper':
      htmlContent = softwareDeveloper;
      break;
    case 'iosdeveloper':
      htmlContent = iosDeveloper;
      break;
    case 'androiddeveloper':
      htmlContent = androidDeveloper;
      break;
    case 'reactwebdeveloper':
      htmlContent = reactWebDeveloper;
      break;
    case 'reactnativedeveloper':
      htmlContent = reactNativeDeveloper;
      break;
    case 'projectmanager':
      htmlContent = projectManager;
      break;
    case 'softwareqaspecialist':
      htmlContent = softwareQaSpecialist;
      break;
    case 'uxexperiencedesigner':
      htmlContent = uxExperienceDesigner;
      break;
    default:
      break;
  }

  if (careersContent?.careers) {
    yamlObj = careersContent.careers.find(career => career.id === id) || null;
  }
}

export default DetailPage;

export const query = graphql`
  query DetailPageQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
