import React from 'react';

type CareerBodyPropsType = {
  htmlContent: string;
};

function CareerBody({ htmlContent }: CareerBodyPropsType) {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}

export default CareerBody;
